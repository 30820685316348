import http from '../http/http-telao';

export default class ProposicaoServiceTelao {
    constructor(dominio) {
        this._dominio = dominio;
    }

    /**
     * Retorna as votações de uma determinada proposição
     *
     * @param {int} proposicaoId
     */
    getVotacoes(proposicaoId) {
        return http.get(`/${this._dominio}/proposicao/${proposicaoId}/votacoes`);
    }

    getProposicoes(sessaoId) {
        return http.get(`/${this._dominio}/sessao/${sessaoId}/proposicoes`);
    }
}
