import http from '../http/http-telao';

export default class VotacaoServiceTelao {
    constructor(dominio) {
        this._dominio = dominio;
    }

    show(id) {
        return http.get(`/${this._dominio}/votacao/${id}`);
    }

    getAberta(sessaoId) {
        return http.get(`/${this._dominio}/votacao/aberta?sessaoId=${sessaoId}`);
    }

    getEncerradas(sessaoId) {
        return http.get(`/${this._dominio}/votacao/anteriores?sessaoId=${sessaoId}`);
    }

    getResultado(votacaoId) {
        return http.get(`/${this._dominio}/votacao/${votacaoId}/resultado`);
    }
}
